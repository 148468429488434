import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../constants/imports';
import { CircularProgress } from '@mui/material';
import PinInput from 'react-pin-input'; // Import the PinInput library
import { ClipLoader } from 'react-spinners';

const VerifyPin = () => {
  const navigate = useNavigate();
  const [pins, setPins] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const pinRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(
    () => {
      if (localStorage.getItem('isAuthenticated')) {
        navigate('/home');
      }
    },
    [navigate]
  );

  useEffect(
    () => {
      if (localStorage.getItem('setPin')) {
        navigate('/setpin');
      }
    },
    [navigate]
  );

  const handleChange = value => {
    setPins(value);
  };

  const handleComplete = value => {
    setPins(value);
  };

  // const handleContinue = async () => {
  //   setError(null);
  //   setIsLoading(true);

  //   // Validate input
  //   if (pins.length < 4) {
  //     setError('Please enter all PIN digits.');
  //     setIsLoading(false);
  //     setPins(''); // Clear the input field
  //     pinRef.current.clear();
  //     return;
  //   }

  //   const accessToken = localStorage.getItem('accessToken');
  //   const authToken = localStorage.getItem('authToken');

  //   try {
  //     let response;
  //     if (accessToken) {
  //       response = await fetch(
  //         'https://finaure-backend.onrender.com/api/verify-pin',
  //         {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //             Authorization: accessToken
  //           },
  //           body: JSON.stringify({ pin: pins }),
  //           credentials: 'include'
  //         }
  //       );
  //     } else {
  //       const phoneNumber = localStorage.getItem('phoneNumber');
  //       response = await fetch(
  //         'https://finaure-backend.onrender.com/api/auth/login',
  //         {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json'
  //           },
  //           body: JSON.stringify({ phoneNumber, pin: pins }),
  //           credentials: 'include'
  //         }
  //       );
  //     }

  //     const data = await response.json();
  //     setIsLoading(false);

  //     if (!response.ok) {
  //       setPins(''); // Clear the input field
  //       pinRef.current.clear();
  //       const newAttempts = attempts + 1;
  //       setAttempts(newAttempts);

  //       // Check if maximum attempts reached
  //       if (newAttempts >= 3) {
  //         setError('Maximum attempts reached. Register again to continue...');
  //         localStorage.clear();
  //         setShowLoader(true); // Show the full-page loader before navigating
  //         setTimeout(() => {
  //           localStorage.clear();
  //           navigate('/signup');
  //         }, 3000); // Navigate after a delay
  //       } else {
  //         setError(
  //           `Invalid PIN. ${4 - newAttempts} attempt${4 - newAttempts !== 1
  //             ? 's'
  //             : ''} remaining.`
  //         );
  //       }

  //       localStorage.removeItem('isAuthenticated');
  //       return;
  //     }

  //     // If PIN is correct, handle success case
  //     if (
  //       data.message === 'PIN verified successfully' ||
  //       data.message === 'Alternative PIN verified successfully' ||
  //       data.message === 'Login successful'
  //     ) {
  //       localStorage.setItem('isAuthenticated', true);
  //       localStorage.setItem('accessToken', data.token);
  //       navigate('/home');
  //     } else if (
  //       data.message === 'Invalid PIN' ||
  //       data.message === 'Incorrect PIN'
  //     ) {
  //       setPins(''); // Clear the input field
  //       pinRef.current.clear();
  //       const newAttempts = attempts + 1;
  //       setAttempts(newAttempts);

  //       // Check if maximum attempts reached
  //       if (newAttempts >= 3) {
  //         setError('Maximum attempts reached. Register again to continue...');
  //         setShowLoader(true); // Show the full-page loader before navigating
  //         setTimeout(() => {
  //           localStorage.clear();
  //           navigate('/signup');
  //         }, 3000);
  //       } else {
  //         setError(
  //           `Invalid PIN. ${4 - newAttempts} attempt${4 - newAttempts !== 1
  //             ? 's'
  //             : ''} remaining.`
  //         );
  //       }

  //       localStorage.removeItem('isAuthenticated');
  //     } else if (
  //       data.message === 'User not registered' ||
  //       data.message === 'User not found'
  //     ) {
  //       setError('User not registered');
  //     } else if (data.message === 'Pin not set') {
  //       setError('Pin not set');
  //       navigate('/setpin');
  //     } else if (
  //       data.message === 'OTP Sent..' ||
  //       data.message ===
  //         '3 incorrect PINs. OTP sent to verify your phone number.'
  //     ) {
  //       localStorage.setItem('authToken', data.token);
  //       if (!accessToken) {
  //         navigate('/verify');
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error verifying PIN:', error);

  //     setError('An error occurred. Please try again later.');
  //     setIsLoading(false);
  //     setPins(''); // Clear the input field on error
  //     pinRef.current.clear();
  //     localStorage.clear();

  //     // Handle error cases
  //     const newAttempts = attempts + 1;
  //     setAttempts(newAttempts);
  //     if (newAttempts >= 3) {
  //       setError('Maximum attempts reached. Register again to continue...');
  //       setTimeout(() => navigate('/signup'), 2000);
  //     } else {
  //       setError(
  //         `Invalid PIN. ${4 - newAttempts} attempt${4 - newAttempts !== 1
  //           ? 's'
  //           : ''} remaining.`
  //       );
  //     }
  //   }
  // };
  const handleContinue = async () => {
    setError(null);
    setIsLoading(true);
  
    // Validate input
    if (pins.length < 4) {
      setError('Please enter all PIN digits.');
      setIsLoading(false);
      setPins(''); // Clear the input field
      pinRef.current.clear();
      return;
    }
  
    const accessToken = localStorage.getItem('accessToken');
    const authToken = localStorage.getItem('authToken');
  
    try {
      let response;
      if (accessToken) {
        response = await fetch(
          'https://finaure-backend.onrender.com/api/verify-pin',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: accessToken,
            },
            body: JSON.stringify({ pin: pins }),
            credentials: 'include',
          }
        );
      } else {
        const phoneNumber = localStorage.getItem('phoneNumber');
        response = await fetch(
          'https://finaure-backend.onrender.com/api/auth/login',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phoneNumber, pin: pins }),
            credentials: 'include',
          }
        );
      }
  
      const data = await response.json();
      setIsLoading(false);
  
      if (!response.ok) {
        setPins(''); // Clear the input field
        pinRef.current.clear();
        const newAttempts = attempts + 1;
        setAttempts(newAttempts);
  
        // Check if maximum attempts reached
        if (newAttempts >= 3) {
          setError('Maximum attempts reached. Register again to continue...');
          setShowLoader(true); // Show the full-page loader before navigating
          setTimeout(() => {
            localStorage.clear();
            navigate('/signup');
          }, 3000); // Navigate after a delay
        } else {
          setError(
            `Invalid PIN. ${4 - newAttempts} attempt${4 - newAttempts !== 1
              ? 's'
              : ''} remaining.`
          );
        }
  
        localStorage.removeItem('isAuthenticated');
        return;
      }
  
      // If PIN is correct, handle success case
      if (
        data.message === 'PIN verified successfully' ||
        data.message === 'Alternative PIN verified successfully' ||
        data.message === 'Login successful'
      ) {
        localStorage.setItem('isAuthenticated', true);
        localStorage.setItem('accessToken', data.token);
        navigate('/home');
      } else if (
        data.message === 'Invalid PIN' ||
        data.message === 'Incorrect PIN'
      ) {
        setPins(''); // Clear the input field
        pinRef.current.clear();
        const newAttempts = attempts + 1;
        setAttempts(newAttempts);
  
        // Check if maximum attempts reached
        if (newAttempts >= 3) {
          setError('Maximum attempts reached. Redirecting to verify page...');
          setShowLoader(true); // Show the full-page loader before navigating
          setTimeout(() => {
            localStorage.clear();
            navigate('/verify'); // Redirect to verify page instead of signup
          }, 3000); // Navigate after a delay
        } else {
          setError(
            `Invalid PIN. ${4 - newAttempts} attempt${4 - newAttempts !== 1
              ? 's'
              : ''} remaining.`
          );
        }
  
        localStorage.removeItem('isAuthenticated');
      } else if (
        data.message === 'User not registered' ||
        data.message === 'User not found'
      ) {
        setError('User not registered');
      } else if (data.message === 'Pin not set') {
        setError('Pin not set');
        navigate('/setpin');
      } else if (
        data.message === 'OTP Sent..' ||
        data.message ===
          '3 incorrect PINs. OTP sent to verify your phone number.'
      ) {
        localStorage.setItem('authToken', data.token);
        if (!accessToken) {
          navigate('/verify');
        }
      }
    } catch (error) {
      console.error('Error verifying PIN:', error);
  
      setError('An error occurred. Please try again later.');
      setIsLoading(false);
      setPins(''); // Clear the input field on error
      pinRef.current.clear();
      localStorage.clear();
  
      // Handle error cases
      const newAttempts = attempts + 1;
      setAttempts(newAttempts);
      if (newAttempts >= 3) {
        setError('Maximum attempts reached. Redirecting to verify page...');
        setShowLoader(true); // Show the full-page loader before navigating
        setTimeout(() => navigate('/verify'), 3000); // Redirect to verify page after a delay
      } else {
        setError(
          `Invalid PIN. ${4 - newAttempts} attempt${4 - newAttempts !== 1
            ? 's'
            : ''} remaining.`
        );
      }
    }
  };
  
  return (
    <Layout pageHeading="Upto 15% Returns." pageBottom="Upto 15% Returns.">
      <div className="max-w-lg w-full mx-2 md:mx-auto text-center font-poppins">
        <h2
          className="text-3xl font-semibold mb-4 text-[#5D20D2]"
          style={{ fontSize: '16px' }}
        >
          Verify finaure PIN
        </h2>
        <p
          className="mb-4 font-semibold text-[#5D20D2]"
          style={{ fontSize: '12px' }}
        >
          PIN helps to keep your account safe
        </p>

        {error &&
          <p className="text-red-500 mb-4 font-normal">
            {error}
          </p>}

        <div className="flex justify-center mb-4">
          <PinInput
            length={4}
            ref={pinRef}
            initialValue=""
            className="flex w-full p-2 xs:p-0"
            secret // To make the input values hidden (password style)
            onChange={handleChange} // Set the pin when changed
            type="numeric"
            inputMode="numeric"
            // Custom padding for inputs
            inputStyle={{
              height: '64px',
              width: '64px',
              fontSize: '24px',
              textAlign: 'center',
              backgroundColor: 'transparent',
              border: '0px solid #ccc',
              borderBottom: '2px solid #ccc',
              marginLeft: '8px',
              marginRight: '8px',
              transition: 'border-color 0.2s'
            }}
            inputFocusStyle={{
              borderBottom: '2px solid #5D20D2' // Focus color (purple)
            }}
            onComplete={handleComplete} // Handle pin complete
          />
        </div>

        <div className="flex justify-center">
          <button
            onClick={handleContinue}
            className="w-[139px] h-[46px] md:w-full bg-purple-600 text-white rounded-md text-lg font-semibold xs:w-[139px] xs:h-[44px] xs:gap-[8px] xs:rounded-tl-[8px] xs:rounded-bl-[8px] xs:opacity-100 flex items-center justify-center"
            style={{ fontWeight: '600' }}
            disabled={isLoading}
          >
            {isLoading
              ? <CircularProgress size={24} color="inherit" />
              : 'Continue'}
          </button>
        </div>
      </div>
      {showLoader &&
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white z-50">
          <div className="text-center">
            <ClipLoader size={50} color="#5D20D2" loading={true} />
            <p className="mt-4 text-lg text-[#5D20D2] font-semibold">
              Navigating back to signup page, please sign up...
            </p>
          </div>
        </div>}
    </Layout>
  );
};

export default VerifyPin;
