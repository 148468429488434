import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../constants/imports';
import { CircularProgress } from '@mui/material';

const NameEmailPage = () => {
  const navigate = useNavigate();

  // State for form inputs, error message, and loading state
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      if (localStorage.getItem('isAuthenticated')) {
        navigate('/home');
      }
    },
    [navigate]
  );

  //   const handleContinue = async () => {
  //   setError(''); // Clear previous errors

  //   // Validation check for the name field
  //   if (!name.trim()) {
  //     setError('Name is required.');
  //     return; // Stop execution if the name is not provided
  //   }

  //   setIsLoading(true); // Start loading

  //   try {
  //     // Replace this with your API endpoint
  //     const response = await fetch(
  //       'https://finaure-backend.onrender.com/api/profile/set-profile',
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Assuming token is stored in local storage
  //         },
  //         body: JSON.stringify({ name, email }),
  //       }
  //     );

  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       throw new Error(errorData.message || 'An error occurred');
  //     }

  //     // Simulate success message and PIN prompt with loading delays
  //     await new Promise((resolve) => setTimeout(resolve, 1000));
  //     setIsLoading(false); // Stop loading
  //     navigate('/setpin'); // Navigate to the next page
  //   } catch (err) {
  //     console.error('Error occurred:', err);
  //     setError(err.message || 'An error occurred. Please try again.');
  //     setIsLoading(false); // Stop loading on error
  //     localStorage.clear(); // Clear storage if error occurs
  //     navigate('/');
  //   }
  // };

  const handleContinue = async () => {
    setError(''); // Clear previous errors

    // Validation check for the name field
    if (!name.trim()) {
      setError('Name is required.');
      return; // Stop execution if the name is not provided
    }

    setIsLoading(true); // Start loading

    try {
      // Check if the profile already exists
      const checkResponse = await fetch(
        'https://finaure-backend.onrender.com/api/profile/check-profile', // Replace with your actual endpoint for checking the profile
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}` // Assuming token is stored in local storage
          }
        }
      );

      if (!checkResponse.ok) {
        const errorData = await checkResponse.json();
        throw new Error(errorData.message || 'Failed to check profile status.');
      }

      const profileData = await checkResponse.json();

      // If the profile exists, navigate directly to /setpin
      if (profileData.exists) {
        setIsLoading(false); // Stop loading
        navigate('/setpin');
        return; // Stop further execution
      }

      // If no profile exists, proceed to create one
      const response = await fetch(
        'https://finaure-backend.onrender.com/api/profile/set-profile',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}` // Assuming token is stored in local storage
          },
          body: JSON.stringify({ name, email })
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || 'An error occurred while setting the profile'
        );
      }

      // Simulate success message and PIN prompt with loading delays
      await new Promise(resolve => setTimeout(resolve, 1000));
      setIsLoading(false); // Stop loading
      navigate('/setpin'); // Navigate to the next page
    } catch (err) {
      console.error('Error occurred:', err);
      setError(err.message || 'An error occurred. Please try again.');
      setIsLoading(false); // Stop loading on error
      localStorage.clear(); // Clear storage if error occurs
      navigate('/');
    }
  };

  return (
    <Layout pageHeading="Buy with SIP." pageBottom="Buy with SIP.">
      <div className="w-full md:w-full text-center flex flex-col justify-center md:mx-0 font-poppins min-h-screen">
        {/* Display any error messages */}
        {error &&
          <div className="mb-4 text-red-500 font-normal">
            {error}
          </div>}

        {/* Name Field */}
        <div className="mb-4 text-left">
          <label
            htmlFor="name"
            className="block text-gray-800 font-medium mb-2"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            placeholder="Enter Name"
            value={name}
            onChange={e => setName(e.target.value)}
            className="w-full border-2 border-gray-300 rounded-md p-3 text-gray-800 placeholder-gray-400 focus:outline-none"
            required
          />
        </div>

        {/* Email Field */}
        <div className="mb-6 text-left">
          <label
            htmlFor="email"
            className="block text-gray-800 font-medium mb-2"
          >
            Email Id
          </label>
          <input
            type="email"
            id="email"
            placeholder="Enter Email (Optional)"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="w-full border-2 border-gray-300 rounded-md p-3 text-gray-800 placeholder-gray-400 focus:outline-none"
          />
        </div>

        {/* Continue Button Wrapper */}
        <div className="flex justify-center">
          <button
            onClick={handleContinue}
            className="w-[139px] h-[46px] md:w-full bg-purple-600 text-white rounded-md text-lg font-semibold xs:w-[139px] xs:h-[44px] xs:gap-[8px] xs:rounded-tl-[8px] xs:rounded-bl-[8px] xs:opacity-100 flex items-center justify-center"
            style={{ fontWeight: '600' }}
            disabled={isLoading}
          >
            {isLoading
              ? <CircularProgress size={24} color="inherit" />
              : 'Continue'}
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default NameEmailPage;
