import React, { useEffect, useState } from 'react';

const AnimatedText = () => {
  const words = ["PLAN", "INVEST", "BUY"];
  const [currentWord, setCurrentWord] = useState(words[0]);
  const [animation, setAnimation] = useState('');

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setAnimation('fadeOutLeft');
      
      setTimeout(() => {
        index = (index + 1) % words.length;
        setCurrentWord(words[index]);
        setAnimation('fadeInRight');
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative z-10 text-center overflow-hidden font-poppins">
      <p 
        className={`
          text-white  font-bold font-poppins
          transition-all duration-500 ease-in-out
          ${animation === 'fadeOutLeft' ? 'opacity-0 -translate-x-full' : ''}
          ${animation === 'fadeInRight' ? 'opacity-100 translate-x-0' : ''}
        `}
        style={{fontSize : '20px' ,fontWeight : '700'}}
      >
        {currentWord}
      </p>
      <div 
        className={`
          w-full h-0.5 bg-white mx-auto 
          transition-all duration-500 ease-in-out
          ${animation === 'fadeOutLeft' ? 'opacity-0 -translate-x-full' : ''}
          ${animation === 'fadeInRight' ? 'opacity-100 translate-x-0' : ''}
        `}
      ></div>
    </div>
  );
};

export default AnimatedText;