import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { Layout } from '../../constants/imports';
import { getOpacity } from '@mui/material/styles/createColorScheme';
import { Google, Facebook, Apple } from '../../constants/assets';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth, googleProvider } from './firebase';
import { signInWithPopup } from 'firebase/auth';
import FullScreenLoader from '../../components/common/Loader';

const SignUpPage = () => {
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState('+91');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('isAuthenticated')) {
      navigate('/home');
    }
  }, []);

  // const handleGoogleSignIn = async () => {
  //   setIsLoading(true);
  //   setErrorMessage(null);
  //   try {
  //     const result = await signInWithPopup(auth, googleProvider);
  //     const token = await result.user.getIdToken();
  //     localStorage.setItem('accessToken', token);

  //     const response = await fetch(
  //       'https://finaure-backend.onrender.com/api/protected',
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: token
  //         },
  //         credentials: 'include'
  //       }
  //     );

  //     if (!response.ok) {
  //       setErrorMessage('Failed to authenticate user');
  //       localStorage.clear();
  //       throw new Error('Failed to authenticate');
  //     }

  //     const userData = await response.json();
  //     console.log(userData.user);
  //     localStorage.setItem('userData', JSON.stringify(userData.user));
  //     if (userData.message === 'Set Pin') {
  //       navigate('/setpin');
  //     } else if (userData.message === 'Verify the pin') {
  //       navigate('/verify-pin');
  //     } else if (userData.message === 'Create Profile') {
  //       navigate('/setprofile');
  //     } else {
  //       navigate('/login');
  //     }
  //   } catch (error) {
  //     setErrorMessage(
  //       'An error occurred during Google sign-in. Please try again.'
  //     );
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleGoogleSignIn = async () => {
    setErrorMessage(null); // Reset any previous error messages
    setIsLoading(true); // Start loading

    try {
      // Display the loader immediately before the popup
      const result = await signInWithPopup(auth, googleProvider);

      // After successful sign-in, get the token
      const token = await result.user.getIdToken();
      localStorage.setItem('accessToken', token);

      const response = await fetch(
        'https://finaure-backend.onrender.com/api/protected',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token
          },
          credentials: 'include'
        }
      );

      if (!response.ok) {
        setErrorMessage('Failed to authenticate user');
        localStorage.clear();
        throw new Error('Failed to authenticate');
      }

      const userData = await response.json();
      console.log(userData.user);
      localStorage.setItem('userData', JSON.stringify(userData.user));

      // Handle navigation based on user data message
      switch (userData.message) {
        case 'Set Pin':
          navigate('/setpin');
          break;
        case 'Verify the pin':
          navigate('/verify-pin');
          break;
        case 'Create Profile':
          navigate('/setprofile');
          break;
        default:
          navigate('/login');
          break;
      }
    } catch (error) {
      setErrorMessage(
        'An error occurred during Google sign-in. Please try again.'
      );
    } finally {
      // Stop loading only after navigation occurs
      // Optional: Add a short delay here if necessary for smoother transition
      setTimeout(() => setIsLoading(false), 100); // Adjust timing if needed
    }
  };

  const handleContinue = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    try {
      const response = await axios.post(
        'https://finaure-backend.onrender.com/api/auth/register-phone',
        {
          phoneNumber: `${countryCode}${phoneNumber}`
        }
      );
      console.log(response);

      if (
        response.data.message ===
        'OTP sent successfully. Please verify your phone number.'
      ) {
        localStorage.setItem('phoneNumber', `${countryCode}${phoneNumber}`);
        navigate('/verify');
      }
    } catch (error) {
      let errorMsg = 'An unexpected error occurred. Please try again later.';
      if (error.response) {
        const { status, data } = error.response;

        // Log the error response for debugging
        console.error('Error Response:', data);

        // Handle specific cases based on status codes or messages
        switch (status) {
          case 400:
            if (data.message === 'Phone number is required') {
              errorMsg = 'Please provide a phone number.';
            } else if (
              data.message === 'User already exists. Please login to continue'
            ) {
              errorMsg =
                'This phone number is already registered. Please log in.';
            } else {
              errorMsg = data.message; // Use the specific message if available
            }
            break;
          case 500:
            errorMsg =
              'An unexpected error occurred while sending the OTP. Please try again later.';
            break;
          default:
            errorMsg = data.message || errorMsg; // Fallback to a general message
            break;
        }
      } else {
        // Network error or no response
        console.error('Network Error:', error);
        errorMsg = 'Network error. Please check your connection.';
      }

      setErrorMessage(errorMsg); // Set the derived error message
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout
      pageHeading="SIP for Everything."
      textBoxWidth="248px"
      pageBottom="SIP for Everything."
    >
      {isLoading &&
        <FullScreenLoader message="Authenticating, please wait..." />}
      <Box
        sx={{
          width: '100%',
          maxWidth: '400px',
          mx: 'auto',
          p: 3,
          fontFamily: 'Poppins, sans-serif'
        }}
        className="font-poppins"
      >
        <Typography
          variant="h4"
          align="center"
          className="font-poppins font-bold text-2xl sm:text-3xl"
          style={{
            fontWeight: 600,
            fontSize: window.innerWidth >= 640 ? '30px' : '30px'
          }}
        >
          WELCOME
        </Typography>
        <Typography
          className="font-poppins font-medium text-sm sm:text-sm text-center"
          style={{
            fontFamily: '"Poppins", sans-serif',
            fontSize: '14px',
            margin: '0',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00908em',
            marginBottom: '24px'
          }}
        >
          Sign Up to get started
        </Typography>

        {errorMessage &&
          <Box mb={2}>
            <Typography
              color="error"
              className="font-normal"
              fontSize="14px"
              textAlign="center"
            >
              {errorMessage}
            </Typography>
          </Box>}
        <div
          style={{
            position: 'relative',
            marginBottom: '2rem',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth: '394px',
            height: '56px',
            borderRadius: '16px', // Add overall border radius to the container
            overflow: 'hidden', // Ensure no overflow for rounded corners
            backgroundColor: '#F0EDFF' // Set the same background color for both
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <span
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: '14px',
                fontWeight: '500',
                width: '51px',
                height: '56px',
                lineHeight: '18px',
                borderRadius: '16px',
                backgroundColor: '#E1D9FF',
                color: '#000',
                display: 'flex', // Use flexbox for the span to center text vertically
                alignItems: 'center', // Center text vertically in the span
                justifyContent: 'center' // Center text horizontally in the span
              }}
            >
              +91
            </span>
          </div>

          <input
            type="tel"
            placeholder="Enter mobile number"
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            style={{
              flex: '1',
              height: '56px',
              border: 'none', // Remove the border
              paddingLeft: '12px', // Padding to align text
              background: '#F0EDFF', // Make the input background transparent
              fontFamily: 'Poppins, sans-serif',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '14px',
              outline: 'none'
            }}
          />
        </div>

        <Box display="flex" justifyContent="center" mb={3}>
          <button
            onClick={handleContinue}
            className="w-[140px] h-[46px] md:w-full bg-purple-600 text-white rounded-md text-lg font-semibold xs:w-[139px] xs:h-[44px] xs:py-[8px] xs:px-[32px] xs:gap-[8px] xs:rounded-tl-[8px] xs:rounded-bl-[8px] xs:opacity-100 flex items-center justify-center"
            style={{ fontWeight: '600' }}
            disabled={isLoading}
          >
            {isLoading
              ? <CircularProgress size={24} color="inherit" />
              : 'Continue'}
          </button>
        </Box>

        <Box mb={3} display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="body2"
            className="text-sm sm:text-sm  flex items-center font-poppins"
            style={{
              fontSize: window.innerWidth >= 640 ? '14px' : '14px',
              color: '#98A2B3'
            }}
          >
            Already Have An Account?
          </Typography>
          <Link
            to="/login"
            className="text-[#5D20D2] ml-1 font-normal no-underline text-sm sm:text-sm flex items-center font-poppins"
            style={{ fontSize: '14px' }}
          >
            Log In
          </Link>
        </Box>

        <Box display="flex" alignItems="center" mb={3}>
          <Box flexGrow={1} height="1px" bgcolor="divider" />
          <Typography variant="body2" color="text.secondary" px={2}>
            or
          </Typography>
          <Box flexGrow={1} height="1px" bgcolor="divider" />
        </Box>

        <Box display="flex" justifyContent="center" gap={2}>
          <button
            onClick={handleGoogleSignIn}
            className="flex font-poppins items-center justify-center border border-purple-600 rounded p-2 w-96 h-10 hover:bg-[#F0EDFF] xs:hover:bg-white sm:hover:bg-white hover:text-black transition-colors"
          >
            <img src={Google} alt="Google" className="h-6" />
            <span className="ml-2">Sign up with Google</span>
          </button>
        </Box>
      </Box>
    </Layout>
  );
};

export default SignUpPage;
