import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../constants/imports';
import { CircularProgress } from '@mui/material';
import PinInput from 'react-pin-input'; // Import the PinInput library

const PinPage = () => {
  const navigate = useNavigate();
  const [pins, setPins] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleContinue = async () => {
    setIsLoading(true); // Start loading
    setError(''); // Clear any previous errors

    if (pins.length < 4) {
      setError('Please enter all PIN digits.');
      setIsLoading(false); // Stop loading on error
      return;
    }

    try {
      // Simulate network request delay
      await new Promise(resolve => setTimeout(resolve, 2000));

      localStorage.setItem('pin', pins);
      navigate('/confirmpin');
    } catch (err) {
      console.error('Error occurred:', err);
      setError('Please try again.');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  useEffect(
    () => {
      if (localStorage.getItem('isAuthenticated')) {
        navigate('/home');
      }
    },
    [navigate]
  );

  return (
    <Layout pageHeading="Upto 15% Returns." pageBottom="Upto 15% Returns.">
      <div className="max-w-lg w-full mx-2 md:mx-auto text-center font-poppins">
        <h2
          className="text-3xl font-semibold mb-4 text-[#5D20D2]"
          style={{ fontSize: '16px' }}
        >
          Set finaure PIN
        </h2>
        <p
          className="mb-4 font-semibold text-[#5D20D2]"
          style={{ fontSize: '12px' }}
        >
          PIN helps to keep your Account safe
        </p>

        {error &&
          <p className="text-red-600 mb-4 font-normal">
            {error}
          </p>}

        <div className="flex justify-center mb-4">
          <PinInput
            length={4}
            initialValue=""
            className="flex w-full p-2 xs:p-0"
            secret // To make the input values hidden (password style)
            onChange={value => setPins(value)} // Set the pin when changed
            type="numeric"
            inputMode="numeric"
            inputStyle={{
              height: '64px',
              width: '64px',
              fontSize: '24px',
              textAlign: 'center',
              backgroundColor: 'transparent',
              border: '0px solid #ccc',
              borderBottom: '2px solid #ccc',
              marginLeft: '8px',
              marginRight: '8px',
              transition: 'border-color 0.2s'
            }}
            inputFocusStyle={{
              borderBottom: '2px solid #5D20D2' // Focus color (purple)
            }}
            onComplete={value => setPins(value)} // Handle pin complete
          />
        </div>

        <div className="flex justify-center">
          <button
            onClick={handleContinue}
            className="w-[139px] h-[46px] md:w-full bg-purple-600 text-white rounded-md text-lg font-semibold xs:w-[139px] xs:h-[44px] xs:gap-[8px] xs:rounded-tl-[8px] xs:rounded-bl-[8px] xs:opacity-100 flex items-center justify-center"
            style={{ fontWeight: '600' }}
            disabled={isLoading}
          >
            {isLoading
              ? <CircularProgress size={24} color="inherit" />
              : 'Continue'}
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default PinPage;
