import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import { CheckCircle } from '@mui/icons-material'; // Import the success icon
import { Layout2 } from '../../constants/imports';
import axios from 'axios';

const UnAuthHome = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false); // State to track success

  useEffect(() => {
    if (localStorage.getItem("isAuthenticated")) {
      navigate("/home");
    }
  }, [navigate]);

  const handleSubscribe = async () => {
    setIsLoading(true);
    setErrorMessage(null); // Clear any previous error message

    try {
      const response = await axios.post("https://finaure-backend.onrender.com/api/auth/subscribe-email", {
        email,
      });
      console.log(response);

      if (response.data.message === 'Subscription successful.') {
        // Simulate a delay to show the loader before displaying the success state
        setTimeout(() => {
          setIsSuccess(true);
          setIsLoading(false);
        }, 1000); // Delay for 1 second
      }
    } catch (error) {
      localStorage.clear();
      // Check if error.response and error.response.data exist before accessing message
      const errorMsg = error.response && error.response.data ? error.response.data.message : 'Enter a valid email';
      setErrorMessage(errorMsg); // Update the state with the error message
      setIsLoading(false);
    }
  };

  return (
    <Layout2 pageHeading="SIP for Everything" textBoxWidth="248px" pageBottom="SIP for Everything.">
      <Box sx={{ width: '100%', maxWidth: '400px', mx: 'auto', p: 3, fontFamily: 'Poppins, sans-serif' }} className="font-poppins">

        {isSuccess ? (
          // Success state
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="200px">
            <CheckCircle color="success" style={{ fontSize: '64px', marginBottom: '16px' }} />
            <Typography variant="h5" align="center" className="font-poppins font-bold text-2xl">
              Subscription Successful!
            </Typography>
            <Typography className="font-poppins font-medium text-sm sm:text-sm text-center" style={{ marginTop: '8px' }}>
              Thank you for subscribing to the beta version.
            </Typography>
          </Box>
        ) : (
          // Subscription form
          <>
            <Typography variant="h4" align="center" className="font-poppins font-bold text-2xl sm:text-3xl">
              Welcome to Finaure 
            </Typography>
            <Typography className="font-poppins font-medium text-sm sm:text-sm text-center" style={{ marginBottom: "24px" }}>
              Subscribe Email for using beta version
            </Typography>

            {errorMessage && (
              <Box mb={2}>
                <Typography color="error" className='font-normal' fontSize="14px" textAlign="center">{errorMessage}</Typography>
              </Box>
            )}

            <div style={{
              position: 'relative',
              marginBottom: '1rem',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              maxWidth: '394px',
              height: "56px",
              borderRadius: "16px",
              overflow: 'hidden',
              backgroundColor: '#F0EDFF',
            }}>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  flex: '1',
                  height: '56px',
                  border: 'none',
                  paddingLeft: '12px',
                  background: '#F0EDFF',
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '14px',
                  outline: 'none',
                }}
              />
            </div>

            <Box display="flex" justifyContent="center" mb={3}>
              <button
                onClick={handleSubscribe}
                className="w-[140px] h-[46px] md:w-full bg-purple-600 text-white rounded-md text-lg font-semibold flex items-center justify-center"
                style={{ fontWeight: '600' }}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Subscribe'}
              </button>
            </Box>

            {/* <Box mb={3} display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body2" className="text-sm flex items-center font-poppins" style={{ color: '#98A2B3' }}>
                Already Have An Account?
              </Typography>
              <Link to="/login" className="text-[#5D20D2] ml-1 font-normal no-underline text-sm flex items-center font-poppins">
                Log In
              </Link>
            </Box> */}
          </>
        )}
      </Box>
    </Layout2>
  );
};

export default UnAuthHome;
