import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../constants/imports';
import CircularProgress from '@mui/material/CircularProgress';
import PinInput from 'react-pin-input';

const ConfirmPin = () => {
  const navigate = useNavigate();
  const [pins, setPins] = useState(['', '', '', '']);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      if (localStorage.getItem('isAuthenticated')) navigate('/home');
    },
    [navigate]
  );

  const handleContinue = async () => {
    setError('');
    setLoading(true);

    // Validate input
    if (pins.some(pin => pin === '')) {
      setError('Please enter all PIN digits.');
      setLoading(false);
      return;
    }

    // Join the pins array to form the complete PIN
    const enteredPin = pins.join('');
    if (enteredPin !== localStorage.getItem('pin')) {
      setError('PINs do not match.');
      setLoading(false);
      return;
    }

    const accessToken = localStorage.getItem('accessToken');
    const authToken = localStorage.getItem('authToken');

    try {
      let response;

      // Check if accessToken exists
      if (accessToken) {
        response = await fetch(
          'https://finaure-backend.onrender.com/api/set-pin',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: accessToken
            },
            body: JSON.stringify({ pin: enteredPin }),
            credentials: 'include'
          }
        );
      } else {
        response = await fetch(
          'https://finaure-backend.onrender.com/api/profile/set-pin',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`
            },
            body: JSON.stringify({ pin: enteredPin }),
            credentials: 'include'
          }
        );
      }

      // Handle response
      const data = await response.json();

      if (!response.ok) {
        setError(data.message);
        localStorage.clear();
        navigate('/');
        return;
      }

      console.log(data.message);
      localStorage.setItem('isAuthenticated', true);
      localStorage.removeItem('pin');
      localStorage.removeItem('setPin');
      navigate('/home');
    } catch (error) {
      console.error('Error setting PIN:', error);
      setError('Error setting PIN');
      localStorage.clear();
      navigate('/');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout
      pageHeading="Simplified Investment Platform."
      pageBottom="Simplified Investment Platform."
    >
      <div className="max-w-lg w-full mx-2 md:mx-auto text-center font-poppins">
        <h2
          className="text-3xl font-semibold mb-4 text-[#5D20D2]"
          style={{ fontSize: '16px' }}
        >
          Confirm finaure PIN
        </h2>
        <p
          className="mb-4 font-semibold text-[#5D20D2]"
          style={{ fontSize: '12px' }}
        >
          PIN helps to keep your Account safe
        </p>
        {error &&
          <p className="text-red-500 text-sm mb-4">
            {error}
          </p>}

        <div className="flex justify-center mb-4">
          <PinInput
            length={4}
            initialValue=""
            className="flex w-full p-2 xs:p-0"
            secret
            onChange={value => {
              // Ensure we set the state as an array of digits
              setPins(value.split('').map(digit => digit || ''));
            }}
            type="numeric"
            inputMode="numeric"
            inputStyle={{
              height: '64px',
              width: '64px',
              fontSize: '24px',
              textAlign: 'center',
              backgroundColor: 'transparent',
              border: '0px solid #ccc',
              borderBottom: '2px solid #ccc',
              marginLeft: '8px',
              marginRight: '8px',
              transition: 'border-color 0.2s'
            }}
            inputFocusStyle={{
              borderBottom: '2px solid #5D20D2' // Focus color (purple)
            }}
            onComplete={value => {
              setPins(value.split('').map(digit => digit || '')); // Set pins on completion
            }}
          />
        </div>

        <div className="flex justify-center">
          <button
            onClick={handleContinue}
            className="w-[139px] h-[46px] md:w-full bg-purple-600 text-white text-center rounded-md text-lg font-semibold xs:w-[139px] xs:h-[44px] xs:py-[8px] xs:px-[32px] xs:gap-[8px] xs:rounded-tl-[8px] xs:rounded-bl-[8px] xs:opacity-100"
            style={{
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            disabled={loading}
          >
            {loading
              ? <CircularProgress size={24} color="inherit" />
              : 'Continue'}
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default ConfirmPin;
