import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ConfirmPin, FormPage, HomePage, LoginPage, PinPage, PasswordPin, SignUpPage, VerificationPage } from './constants/imports';
import VerifyPin from './pages/auth/VerifyPin';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/verify" element={<VerificationPage />} />
        <Route path="/setprofile" element={<FormPage />} />
        <Route path="/setpin" element={<PinPage />} />
        <Route path="/password" element={<PasswordPin />} />
        <Route path="/ConfirmPin" element={<ConfirmPin />} />
        <Route path="/verify-pin" element={<VerifyPin />} />
        {/* The /home route now uses the same HomePage component */}
        <Route path="/home" element={<HomePage />} />
        <Route path="/dashboard" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;