import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { Layout } from '../../constants/imports';
import { Google, Facebook, Apple } from '../../constants/assets';
import { auth, googleProvider } from './firebase';
import { signInWithPopup } from 'firebase/auth';
import axios from 'axios';
import FullScreenLoader from '../../components/common/Loader';

const LoginPage = () => {
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState('+91');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('isAuthenticated')) {
      navigate('/home');
    }
  }, []);

  const handleContinue = async () => {
    setLoading(true);
    setError('');

    // Validate phone number
    if (
      phoneNumber.length !== 10 ||
      Number(phoneNumber) < 5000000000 ||
      Number(phoneNumber) > 9999999999
    ) {
      setLoading(false);
      setError('Please enter a valid phone number');
      return;
    }

    try {
      const response = await axios.post(
        'https://finaure-backend.onrender.com/api/auth/check',
        {
          phoneNumber: `${countryCode}${phoneNumber}`
        }
      );
      console.log(response.data);
      if (response.data.message === 'User registered') {
        localStorage.setItem('phoneNumber', `${countryCode}${phoneNumber}`);
        navigate('/verify-pin');
      } else {
        localStorage.clear();
        setError(response.data.message || 'User not registered');
      }
      if (response.data.message === 'User not registered') {
        localStorage.clear();
        setError(response.data.message);
      } else if (response.data.message === 'OTP Sent') {
        localStorage.setItem('phoneNumber', `${countryCode}${phoneNumber}`);
        navigate('/verify');
      } else if (
        response.data.message === 'You are registered. Please Login to continue'
      ) {
        localStorage.setItem('phoneNumber', `${countryCode}${phoneNumber}`);
        navigate('/login'); // Assuming redirection to login
      } else if (response.data.message === 'User registered') {
        localStorage.setItem('phoneNumber', `${countryCode}${phoneNumber}`);
        navigate('/verify-pin');
      }
    } catch (error) {
      localStorage.clear();
      // Check if error.response and error.response.data exist before accessing message
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : 'Please try again.';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // const handleGoogleSignIn = async () => {
  //   setIsLoading(true);
  //   try {
  //     const result = await signInWithPopup(auth, googleProvider);
  //     const token = await result.user.getIdToken();
  //     localStorage.setItem("accessToken", token);

  //     const response = await fetch("https://finaure-backend.onrender.com/api/protected", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: token,
  //       },
  //     });

  //     if (response.status === 401) {
  //       setError('Unauthorized: Token invalid or expired.');
  //       localStorage.clear();
  //       navigate("/login");
  //       return;
  //     }

  //     if (!response.ok) {
  //       const error = await response.text();
  //       setError(error);
  //       localStorage.clear();
  //       navigate("/");
  //       return;
  //     }

  //     const userData = await response.json();
  //     const message = userData.message;
  //     localStorage.setItem('userData', JSON.stringify(userData.user));
  //     if (message === "Set Pin") {
  //       localStorage.setItem("setPin", true);
  //       navigate("/setpin");
  //     } else if (message === "Verify the pin") {
  //       localStorage.removeItem("setPin");
  //       navigate("/verify-pin");
  //     }
  //   } catch (error) {
  //     setError("Unexpected error occurred. Please try again.");
  //     localStorage.clear();
  //     navigate("/");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const handleGoogleSignIn = async () => {
    try {
      // Step 1: Google sign-in popup opens, no loader yet
      const result = await signInWithPopup(auth, googleProvider);

      // Step 2: After successful sign-in, start showing the loader
      setIsLoading(true);

      // Fetch the token from the signed-in user
      const token = await result.user.getIdToken();
      localStorage.setItem('accessToken', token);

      // Step 3: Proceed with the API request, loader is visible during this time
      const response = await fetch(
        'https://finaure-backend.onrender.com/api/protected',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token
          }
        }
      );

      if (response.status === 401) {
        setError('Unauthorized: Token invalid or expired.');
        localStorage.clear();
        setIsLoading(false); // Hide loader if unauthorized
        navigate('/login');
        return;
      }

      if (!response.ok) {
        const error = await response.text();
        setError(error);
        localStorage.clear();
        setIsLoading(false); // Hide loader if error
        navigate('/');
        return;
      }

      // Step 4: Successful authentication, proceed to next screen with delay
      const userData = await response.json();
      const message = userData.message;
      localStorage.setItem('userData', JSON.stringify(userData.user));

      // Redirect accordingly with loader still visible during the delay
      if (message === 'Set Pin') {
        localStorage.setItem('setPin', true);
        setTimeout(() => {
          setIsLoading(false); // Hide loader after navigating
          navigate('/setpin');
        }, 2000); // Simulated delay before navigation
      } else if (message === 'Verify the pin') {
        localStorage.removeItem('setPin');
        setTimeout(() => {
          setIsLoading(false); // Hide loader after navigating
          navigate('/verify-pin');
        }, 2000); // Simulated delay before navigation
      }
    } catch (error) {
      setError('Unexpected error occurred. Please try again.');
      localStorage.clear();
      setIsLoading(false); // Hide loader if there's an error
      navigate('/');
    }
  };

  return (
    <Layout
      pageHeading="SIP for Everything."
      textBoxWidth="248px"
      pageBottom="SIP for Everything."
    >
      {isLoading && <FullScreenLoader message="Redirecting, please wait..." />}
      <Box
        sx={{
          width: '100%',
          maxWidth: '400px',
          mx: 'auto',
          p: 3,
          fontFamily: 'Poppins, sans-serif'
        }}
        className="font-poppins"
      >
        <Typography
          variant="h4"
          align="center"
          className="font-poppins font-bold text-2xl sm:text-3xl"
          style={{
            fontWeight: 600,
            fontSize: window.innerWidth >= 640 ? '30px' : '30px'
          }}
        >
          WELCOME
        </Typography>

        <Typography
          className="font-poppins font-medium text-sm sm:text-sm text-center"
          style={{
            fontFamily: '"Poppins", sans-serif',
            fontSize: '14px',
            margin: '0',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00908em',
            marginBottom: '24px'
          }}
          mb={3}
        >
          Login to get started
        </Typography>

        {error &&
          <Box mb={2}>
            <Typography
              color="error"
              className="font-normal"
              fontSize="14px"
              textAlign="center"
            >
              {error}
            </Typography>
          </Box>}

        <div
          style={{
            position: 'relative',
            marginBottom: '2rem',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth: '394px',
            height: '56px',
            borderRadius: '16px', // Add overall border radius to the container
            overflow: 'hidden', // Ensure no overflow for rounded corners
            backgroundColor: '#F0EDFF' // Set the same background color for both
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <span
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: '14px',
                fontWeight: '500',
                width: '51px',
                height: '56px',
                lineHeight: '18px',
                borderRadius: '16px',
                backgroundColor: '#E1D9FF',
                color: '#000',
                display: 'flex', // Use flexbox for the span to center text vertically
                alignItems: 'center', // Center text vertically in the span
                justifyContent: 'center' // Center text horizontally in the span
              }}
            >
              +91
            </span>
          </div>

          <input
            type="tel"
            placeholder="Enter mobile number"
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            style={{
              flex: '1',
              height: '56px',
              border: 'none', // Remove the border
              paddingLeft: '12px', // Padding to align text
              background: '#F0EDFF', // Make the input background transparent
              fontFamily: 'Poppins, sans-serif',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '14px',
              outline: 'none'
            }}
          />
        </div>
        <Box display="flex" justifyContent="center" mb={3}>
          <button
            onClick={handleContinue}
            className="w-[140px] h-[46px] md:w-full bg-purple-600 text-white rounded-md text-lg font-semibold flex items-center justify-center" // Added flex classes
            style={{ fontWeight: '600' }}
            disabled={loading}
          >
            {loading
              ? <CircularProgress size={24} color="inherit" />
              : 'Continue'}
          </button>
        </Box>

        <Box mb={3} display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="body2"
            className="text-sm sm:text-sm text-[#98A2B3] flex items-center font-poppins"
            style={{
              fontSize: window.innerWidth >= 640 ? '14px' : '14px'
            }}
          >
            Already Have An Account?
          </Typography>
          <Link
            to="/signup"
            className="text-[#5D20D2] ml-1 font-normal no-underline text-sm sm:text-sm flex items-center"
            style={{ fontSize: '14px' }}
          >
            Sign Up
          </Link>
        </Box>

        <Box display="flex" alignItems="center" mb={3}>
          <Box flexGrow={1} height="1px" bgcolor="divider" />
          <Typography variant="body2" color="text.secondary" px={2}>
            or
          </Typography>
          <Box flexGrow={1} height="1px" bgcolor="divider" />
        </Box>

        <Box display="flex" justifyContent="center" gap={2}>
          <button
            onClick={handleGoogleSignIn}
            className="flex font-poppins items-center justify-center border border-purple-600 rounded p-2 w-96 h-10 hover:bg-[#F0EDFF] xs:hover:bg-white sm:hover:bg-white hover:text-black transition-colors"
          >
            <img src={Google} alt="Google" className="h-6" />
            <span className="ml-2">Log in with Google</span>
          </button>
        </Box>
      </Box>
    </Layout>
  );
};

export default LoginPage;
