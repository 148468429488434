import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const FullScreenLoader = ({ message }) => (
  <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white z-50">
    <div className="text-center">
      <ClipLoader size={50} color="#5D20D2" loading={true} />
      <p className="mt-4 text-lg text-[#5D20D2] font-semibold">
        {message || "Please wait..."}
      </p>
    </div>
  </div>
);

export default FullScreenLoader;
