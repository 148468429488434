import React, { useState, useEffect } from 'react';
import { Logo4 } from '../../constants/assets';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../auth/firebase';
import UnAuthHome from './UnAuthHome';
import { Avatar, Button, Menu, MenuItem } from '@mui/material';

const HomePage = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();
  const userData = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    setIsAuthenticated(localStorage.getItem('isAuthenticated'));
  }, []);

  const handleSignUp = () => {
    navigate('/signup');
  };

  const handleSignIN = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  return (
    <div className="flex flex-col min-h-screen bg-[#f8f5ff] font-poppins" >
      {/* Header */}
      <div className="flex justify-between items-center p-4 bg-white shadow-md font-poppins  ">
        <img src={Logo4} alt="finaure Logo4" className="h-11 md:ml-14 " />
        <div className="flex items-center md:mr-10 font-poppins">
  {isAuthenticated ? (
    <>
      <Button onClick={handleMenuOpen} startIcon={
        <Avatar src={userData?.picture} alt="Profile">
          {userData ? null : 'U'}
        </Avatar>
      }>
        {userData ? userData.name : 'My Account'}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={handleMenuClose}>My Account</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>

    </>
  ) : (
            <>
            
              <button onClick={handleSignIN} className="bg-[white] border-2 border-purple-500 text-black px-4 py-2 rounded-full hover:bg-[#4A1AA8] hover:text-white">
                Sign in
              </button>
              
            </>
          )}
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-grow justify-between items-center gap-4">
        {isAuthenticated ? (
          <div className="text-center w-full">
            <h4 className="font-bold text-2xl mb-4">Welcome to Your Dashboard</h4>
            <p className="mb-4">Manage your investments and track your financial goals here.</p>
            <div className="flex justify-center gap-4">
              <button className="bg-[#5D20D2] text-white px-4 py-2 rounded hover:bg-[#4A1AA8]">View Portfolio</button>
              <button className="bg-[#5D20D2] text-white px-4 py-2 rounded hover:bg-[#4A1AA8]">Make Investment</button>
            </div>
          </div>
        ) : (
          <>
            {/* Left Side */}
            <UnAuthHome className='w-full' />
          </>
        )}
      </div>
    </div>
  );
};

export default HomePage;
