import React, { useEffect, useState } from 'react';

import AnimatedText from './animated';
import { Logo4 } from '../../constants/assets';
import { useLocation } from 'react-router';



const Layout = ({ children, pageHeading, pageBottom, textBoxWidth }) => {
  const headings = pageHeading.split(',');
  const bottom = pageBottom.split(',');
  const [backgroundPosition, setBackgroundPosition] = useState('0% 0%');
  const location = useLocation(); // Get current location

  useEffect(() => {
    // Get the current page counter from localStorage
    let pageCounter = localStorage.getItem('pageCounter');
    
    // If not present, initialize it to 0
    if (!pageCounter) {
      pageCounter = 0;
    } else {
      pageCounter = parseInt(pageCounter, 10); // Convert string to integer
    }

    // Increment the counter for every page render
    pageCounter += 1;

    // Store the updated counter back to localStorage
    localStorage.setItem('pageCounter', pageCounter);

    // Calculate the background position shift based on the counter
    const newPosition = `${pageCounter * 150}% ${pageCounter * 150}%`;

    // Apply the new background position
    setBackgroundPosition(newPosition);

  }, [location]);


  return (
    <div className="flex justify-center items-center min-h-screen w-full">
      

      <div className="sm:w-full md:w-[860px] md:h-[540px] flex flex-col md:flex-row bg-white md:rounded-[20px] shadow-[rgba(0,0,0,0.2)_0px_3px_1px_-2px,rgba(0,0,0,0.14)_0px_2px_2px_0px,rgba(0,0,0,0.12)_0px_1px_5px_0px] ">
        {/* Left section for larger screens */}
        <div className="hidden md:flex md:w-1/2 bg-[#5D20D2] p-8 items-center justify-center relative rounded-l-[20px]">
        <div
            className={`absolute inset-0 bg-[url('../assets/Frame.svg')] bg-cover bg-center opacity-50 rounded-l-[20px] bg-animate`}
            style={{
              backgroundPosition: backgroundPosition,
              transition: 'background-position 0s ease', // Ensure the transition is smooth
            }}
          ></div>
          <div className="absolute top-8 left-8 z-20 text-start font-raleway">
            {headings.map((heading, index) => (
              <h1 key={index} className="text-white font-raleway font-medium h-full" style={{ fontSize: '32px' }}>
                {heading.trim()}
              </h1>
            ))}
          </div>
          <div className="relative z-10 text-center">
            <AnimatedText />
          </div>
        </div>

        
          <div className="flex flex-col sm:w-screen md:w-1/2 md:h-full h-screen w-screen xs:overflow-hidden">
          {/* Main content area */}
          <div className="absolute xs:hidden sm:hidden md:hidden top-8 left-4">
            {/* <img src={Logo4} alt="Company Logo" className="w-32 mb-3 h-16 z-40" /> */}
          </div>
          <div className="flex-grow  xs:hidden sm:hidden bg-white p-2 md:p-8 flex items-center justify-center overflow-y-hidden md:rounded-[20px]">
            {children}
          </div>
         
          <div className="md:hidden flex flex-col xs:h-[60vh]sm:h-[75vh]  h-[60vh] md:h-auto overflow-y-hidden">
            {/* Logo area */}
         
            {/* Main content area */}
            <div className="flex-grow bg-white p-4 md:p-8 flex items-center justify-center overflow-y-hidden">
              {children}
            </div>
          </div>

          {/* Bottom section for mobile */}
          <div className="md:hidden bg-[#5D20D2] p-8 text-white flex flex-col justify-start items-start text-raleway xs:mt-2 xs:h-[40vh] h-[40vh] overflow-y-hidden">
          {bottom.map((bottom, index) => (
            <h2 key={index} className="w-[325px] font-medium text-raleway relative z-10" style={{ fontSize: '32px' }}>
              {bottom.trim()}
            </h2>
              ))}
            <div className="relative z-10 text-left  xs:mt-5 sm:mt-14">
              <AnimatedText />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;