import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../constants/imports';
import { CircularProgress } from '@mui/material';

const PasswordPin = () => {
  const navigate = useNavigate();
  const [pins, setPins] = useState(['', '', '', '']);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (index, value) => {
    const newPins = [...pins];
    newPins[index] = value;
    setPins(newPins);

    if (value && index < 3) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !pins[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  const handleContinue = async () => {
    setIsLoading(true); // Start loading
    setError(''); // Clear previous error messages

    // Validate input pins
    if (pins.some(pin => pin === '')) {
      setError('Please enter all PIN digits.');
      setIsLoading(false); // Stop loading on error
      return;
    }

    // Simulate API call or action
    try {
      // Replace this with your actual logic (e.g., an API call)
      await new Promise(resolve => setTimeout(resolve, 2000)); // Simulating a network request

      // If validation is successful, navigate to the next page
      navigate('/verify');
    } catch (err) {
      console.error('Error occurred:', err);
      setError('An error occurred. Please try again.'); // Handle any errors here
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <Layout pageHeading="Plan your Goals." pageBottom="Plan your Goals.">
      <div className="max-w-lg w-full mx-2 md:mx-auto text-center font-poppins">
        <h2
          className="text-3xl font-semibold mb-4 text-[#5D20D2]"
          style={{ fontSize: '16px' }}
        >
          Enter finaure PIN
        </h2>
        <p
          className="mb-4 font-semibold text-[#5D20D2]"
          style={{ fontSize: '12px' }}
        >
          PIN helps to keep your Account safe
        </p>

        {/* Display any error messages */}
        {error &&
          <p className="text-red-600 mb-4 font-normal">
            {error}
          </p>}

        <div className="flex justify-between mb-4">
          {pins.map((pin, index) =>
            <div key={index} className="w-16 mx-2">
              <input
                ref={inputRefs[index]}
                type="password"
                maxLength={1}
                value={pin}
                onChange={e => handleChange(index, e.target.value)}
                onKeyDown={e => handleKeyDown(index, e)}
                className="w-full h-16 text-center text-2xl bg-transparent border-b-2 border-gray-300 focus:border-purple-600 outline-none transition-colors"
              />
            </div>
          )}
        </div>

        <button
          onClick={handleContinue}
          className="w-[139px] h-[46px] md:w-full bg-purple-600 text-white rounded-md text-lg font-semibold xs:w-[139px] xs:h-[44px] xs:py-[8px] xs:px-[32px] xs:gap-[8px] xs:rounded-tl-[8px] xs:rounded-bl-[8px] xs:opacity-100 flex items-center justify-center"
          style={{ fontWeight: '600' }}
          disabled={isLoading}
        >
          {isLoading
            ? <CircularProgress size={24} color="inherit" />
            : 'Continue'}
        </button>
      </div>
    </Layout>
  );
};

export default PasswordPin;
