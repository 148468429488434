import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../constants/imports';
import axios from 'axios';

const VerificationPage = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(Array(4).fill('')); // Store OTP digits in an array
  const [errorMessage, setErrorMessage] = useState('');
  const [isResending, setIsResending] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state for continue button
  const phoneNumber = localStorage.getItem('phoneNumber'); // Get the phone number from local storage

  // Handle OTP input changes
  useEffect(() => {
    if (localStorage.getItem("isAuthenticated")) {
      navigate("/home");
    }
  }, []);

  const handleChange = (element, index) => {
    const value = element.value;
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field
      if (index < 3 && element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  // Handle OTP verification
  const handleContinue = async () => {
    setLoading(true); // Set loading to true when starting the verification
    setErrorMessage(''); // Clear any previous error messages

    try {
      const response = await axios.post('https://finaure-backend.onrender.com/api/auth/verify-otp', {
        phoneNumber: phoneNumber, // Replace with actual phone number
        otp: otp.join(''),
      });

      if (response.data.message === 'Please set your profile') {
        localStorage.setItem('authToken', response.data.token);
        localStorage.removeItem('phoneNumber');
        navigate('/setprofile');
      }
      if (response.data.message === 'Please set your PIN') {
        localStorage.setItem('authToken', response.data.token);
        localStorage.removeItem('phoneNumber');
        navigate('/setpin');
      }
      if (response.data.message === 'OTP verified and user authenticated') {
        localStorage.setItem('authToken', response.data.token);
        localStorage.removeItem('phoneNumber');
        navigate('/setpin');
      }
    } catch (error) {
      setErrorMessage('Invalid OTP. Please try again.');
    } finally {
      setLoading(false); // Set loading to false when done
    }
  };

  // Handle Resend OTP
  const handleResendOtp = async () => {
    setIsResending(true);
    try {
      await axios.post('https://finaure-backend.onrender.com/api/auth/resend-otp', {
        phoneNumber: phoneNumber, // Replace with actual phone number
      });
      setErrorMessage('OTP resent successfully.');
    } catch (error) {
      setErrorMessage('Error resending OTP. Please try again later.');
    } finally {
      setIsResending(false);
    }
  };

  // Extract the last two digits of the phone number for display
  const lastTwoDigits = phoneNumber ? phoneNumber.slice(-2) : 'XX'; // Default to 'XX' if no number

  return (
    <>
      <Layout pageHeading="Up to 30% Discount." pageBottom="Up to 30% Discount.">
        <div className="max-w-md font-poppins text-center w-full">
          <h2 className="sm:text-xl font-semibold mb-4 w-72 flex justify-center mx-auto">
            Sent a verification code to verify your mobile number
          </h2>
          <p className="mb-4 font-semibold text-[#5D20D2]">
            Sent to +91 ********{lastTwoDigits}
          </p>

          <div className="flex justify-around md:justify-between mb-4">
            {[...Array(4)].map((_, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                className="w-12 h-12 md:w-16 bg-[#EBEBFF] md:h-16 text-center text-2xl rounded-md"
                value={otp[index]}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()} // Select the input when focused
              />
            ))}
          </div>

          {errorMessage && (
            <p className="text-red-400 mb-4 font-normal">{errorMessage}</p>
          )}

          <div className="text-center mb-4 flex flex-col">
            <p>Didn't get OTP yet?</p>
            <button
              className="text-purple-600 font-poppins font-bold"
              onClick={handleResendOtp}
              disabled={isResending}
            >
              {isResending ? 'Resending...' : 'Resend OTP'}
            </button>
          </div>

          <button
            onClick={handleContinue}
            className="w-[139px] h-[46px] md:w-full bg-purple-600 text-white text-center rounded-md text-lg font-semibold xs:w-[139px] xs:h-[44px] xs:py-[8px] xs:px-[32px] xs:gap-[8px] xs:rounded-tl-[8px] xs:rounded-bl-[8px] xs:opacity-100"
              style={{ fontWeight: "600" }}
              disabled={loading} // Disable button while loading
          >
            {loading ? 'Verifying...' : 'Continue'} {/* Change button text when loading */}
          </button>
          
        </div>
      </Layout>
    </>
  );
};

export default VerificationPage;
